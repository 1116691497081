/* eslint-disable max-len */
import { lighten } from 'polished'
import mixins from './mixins'

export const colors = {
  white: '#fff',
  black: '#000',
  primary: '#000',
  faded: lighten('0.1', '#000'), // 'rgba(0, 0, 0, 0.1)',
  divider: '#333333',
  tag: '#1E1E1E',
  gray600: '#141414',
  gray500: '#111111',
  gray400: '#4F4F4F',
  gray300: '#9B9B9B',
  gray200: '#BABABA',
  gray100: '#DEDEDE',
  pageBackground: '#0E0E10',
  error: '#C00009',
  success: '#139F4D',
}

export const fonts = {
  primary: '"Source Sans Pro", Helvetica, serif',
  monospace: '"Source Code Pro", Consolas, "Fira Sans", monospace',
}

// export const spacings = [0, 8, 16, 24, 32, 48, 64, 72, 96, 120]
export const spacings = [0, 0.5, 1, 1.5, 2, 3, 4, 4.5, 6, 7.5]

export const grid = {
  gap: 32,
  gapLg: 72,
}

// values in ems
export const breakpoints = {
  xs: 0,
  sm: 36, // 576px
  md: 48, // 768px
  lg: 62, // 992px
  xl: 75, // 1200px
  xxl: 87.5, // 1400px
  xxxl: 100, // 1600px
}

export const widths = {
  sm: 640,
  md: 720,
  lg: 960,
  xl: 1200,
  xxl: 1400,
  xxxl: 1920,
}

export const dimensions = {
  fontSize: {
    base: 1,
    regular: 1.2,
    large: 1.35,
    small: 0.875,
  },
  fontWeight: {
    normal: 400,
    medium: 600,
    bold: 700,
  },
  headingSizes: {
    display: 6,
    displayMd: 4.5,
    displaySm: 3.5,
    h1: 3,
    h2: 2,
    h3: 1.5,
    h4: 1.2,
    h5: 0.875,
  },
  lineHeight: {
    regular: 1.65,
    heading: 1.2,
  },
  containerPadding: 1.5,
}

export const animation = {
  durations: {
    fastest: '0.2s',
    fast: '0.5s',
    slow: '1s',
  },
}

export const heights = {
  nav: 80,
  navLarge: 112,
  logo: 48,
  logoSmall: 32,
}

export const borders = {
  default: 2,
  large: 4,
  radius: {
    sm: '4px',
    lg: '8px',
  },
}

export const zindex = {
  noise: -1,
  social: 3,
  nav: 4,
  logo: 5,
  modals: 6,
}

export const theme = {
  colors,
  fonts,
  spacings,
  grid,
  breakpoints,
  widths,
  dimensions,
  animation,
  heights,
  borders,
  zindex,
  mixins,
}

export type ThemeType = typeof theme
