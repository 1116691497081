export default {
  visuallyHidden: `
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  `,
  visuallyHiddenOff: `
    clip: auto;
    height: auto;
    overflow: visible;
    position: static !important;
    width: auto;
  `,
}
